import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { MediaQueries, Sizes, Colors } from 'environment';

export const Container = styled.section`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 4rem;
	margin-bottom: 12rem;

	@media ${MediaQueries.phone} {
		margin-bottom: 6rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

export const Title = styled.h1`
	margin-bottom: 2rem;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
		margin-bottom: 1rem;
	}
`;

export const Updated = styled.p``;

export const MarkdownContent = styled.div`
	font-family: 'Open Sans';
	font-size: 1.8rem;
	line-height: 2.7rem;
	white-space: pre-line;

	a {
		border-bottom: 0.2rem transparent solid;
		transition: border-color 0.2s;

		&:hover {
			border-color: ${Colors.green};
		}
	}

	img {
		max-width: 100%;
		max-height: 100%;
	}

	table {
		width: 100%;

		& > tbody {
			text-align: center;
		}
	}

	h3 {
		font-family: 'Open Sans';
		font-weight: 600;
		margin-bottom: 4rem;
		margin-top: 8rem;
	}

	@media ${MediaQueries.phone} {
		h1,
		h2 {
			font-size: 3.6rem;
			line-height: 5rem;
		}
		h3 {
			font-family: 'Comfortaa';
			font-size: 2.4rem;
			font-weight: bold;
			line-height: 2.9rem;
			margin-bottom: 2rem;
			margin-top: 4rem;
		}
	}
`;
