import React from 'react';

import { CookiesPolicy } from 'components/Cookies';
import { BannerCta, SEO } from 'components/UI';
import content from 'data/cookie-policy.json';

export default function Cookies() {
	return (
		<>
			<SEO />
			<CookiesPolicy />
			<BannerCta {...content.cta} />
		</>
	);
}
