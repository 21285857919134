import React from 'react';

import { title, updated } from 'data/cookie-policy.json';
import { useCookiesPolicyMarkdown } from 'hooks';

import { Container, Content, MarkdownContent, Title, Updated } from './CookiesPolicy.style';

export function CookiesPolicy() {
	const data = useCookiesPolicyMarkdown();

	return (
		<Container>
			<Content>
				<Title>{title}</Title>
				<Updated>{updated}</Updated>
				{data.length > 0 && <MarkdownContent dangerouslySetInnerHTML={{ __html: data }} />}
			</Content>
		</Container>
	);
}
